export default {
    home: {
        language: "Language",
        banner_p1: "Trading foreign exchange, commodities, indices, stocks through the platform",
        banner_p2: "",
        banner_msg1: "And more popular financial products",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "Register Now",
        banner_link2: "Register",
        swiper_h1: "High-Performance",
        swiper_h2: "Matching engine",
        swiper_h3: "Financial level",
        swiper_h4: "Safety protection",
        swiper_h5: "Product",
        swiper_h6: "Rich diverse",
        swiper_h7: "Globalization",
        swiper_h8: "Layout",
    },
    list: {
        title: "Hot",
        th1: "Spot",
        th2: "Last Price",
        th3: "24 Hr Increase",
        th4: "Quotes",
        th5: "Go Trade",
    },
    info1: {
        tit1: "Core Platform Features",
        tit2: "Perpetual Contracts Spot",
        p1: "Robust 3rd Gen. Transaction Matching System",
        p2: "Ensures that all core operations are completed within a 10th of a second.",
        p3: "Smarter Trading Tools",
        p4: "CXMDirect offers independently developed and exclusive functions such as K-Line Quick Trading, dual-price liquidation, trailing stop, and more functionality that enables traders to make smarter investment decisions.",
        p5: "Flexible to Your Strategy",
        p6: "With the ability to order up to 20 positions with split leverages or merge leverage up to 125x, and set up to 50 TP/SL positions on every position, we can meet the risk appetite of traders at all levels.",
        btn: "Trade Now"
    },
    info2: {
        tit1: "Transaction function one",
        tit2: " K-Line Quick Trading",
        p1: "Select & Trade On Chart",
        p2: "CXMDirect’s exclusive innovation that provides you with the option to place trades and edit them directly on the chart without switching back and forth between the order page. When the market changes rapidly, you can select the spot right on the chart and place an order within seconds!",
        p3: "Complete Position Management",
        p4: "With this powerful function, you can complete various trading operations on the K-line intuitively and efficiently, including setting leverage, setting TP/SL prices, and adjusting your orders. When the market changes rapidly, you can select the spot right on the chart and place an order within seconds!",
    },
    info3: {
        tit1: "Transaction function two",
        tit2: " Split & Merge Positions",
        p1: "Isolate Multiple Positions",
        p2: "Users can hold multiple positions with different leverages in the same currency. Setting a split position allows you to independently choose and adjust leverage across multiple entry points. You can add margin to the position and even merge separate ones together at will. Short or long up to 20 different split positions in each currency!",
        p3: "Merge Positions to Reduce Risk",
        p4: "Our comprehensive merge function enables users to set positions at different entry points that will simply merge into the same leverage while quickly adjusting the margin and liquidation price. This functionality also allows you to set a trailing stop price on your position which can help you reduce risks but continue for profits if the market continues to move in your favor.",
    },
    info4: {
        tit1: "Transaction function three",
        tit2: " Extensive Take-Profit & Stop-Loss Tools",
        p1: "Three Unique TP/SL Functions",
        p2: "CXMDirect provides 3 TP/SL modes to control your risk and manage profits effectively. You can; 1) Set a TP/SL for the whole position amount, 2) Set a TP/SL with a partial amount of the position margin, 3) Set a conditional TP/SL which allows you to set a trigger price which triggers the position to close at a specific price.",
        p3: "Up to 50 Per Position!",
        p4: "Users can set take-profit and stop-loss points at any time in open positions and pending orders to flexibly control risk. Even more, CXMDirect allows users to set up to 50 take-profit and stop-loss orders for the same position with different amounts of the margin and at different prices. This includes limit orders so that you're ready to manage your risk at any stage of the investment.",
    },
    info5: {
        p1: "Stable operation for 3 years no downtime and no lag",
        p2: "Industry media features",
        p3: "Top KOL cooperations",
        p4: "Satisfied users",
    },
    why: {
        p1: "Why Choose CXMDirect",
        p2: " Strong, Independent R&D Capabilities",
        p3: "Self-developed third-gen trading system from 0 to 1",
        p4: "Exclusive features to give you the ultimate trading experience without fail.   ",
        p5: "Top Security",
        p6: "Hot and cold wallet structure secures assets in isolated methods",
        p7: "Dual means to ensure the safety of your assets",
        p8: "Fortified Risk Control Systems",
        p9: "Dual-price liquidation protection mechanism",
        p10: "to protect highly leveraged traders from incurring significant losses.",
        p11: "Global Innovative Team",
        p12: "Blockchain & tech specialists",
        p13: "Culturally diverse team",
        p14: "24/7 Multi Lingual Support",
        p15: "One minute response time",
        p16: "Dedicated to getting you solutions",
    },
    info6: {
        p1: "Are you ready to start your crypto journey?",
        p2: "Start your cryptocurrency investment now",
    },
    foot: {
        download: "Download",
        copy_success: "Copy Success",
        copy_error: "Copy failed",
    },
    download: {
        h1: "Please use your cell phone to scan the QR code to download",
        copy: "Copy",
        down: "Download",
        rate: "Score",
        age: "Age",
        complain: " Complaints",
        tit1: "New feature",
        version: "Versions",
        p1: "This release includes bug fixes and performance improvements",
        tit2: "Applications",
        p2: "Welcome to IDEX Global, one of the world's leading digital asset exchanges! Trade over 1,100 cryptocurrencies including Bitcoin (BTC), Ethereum (ETH), Dogcoin (DOGE), MX Token (MX) and more. As the world's leading cryptocurrency exchange, CXMDirect is known for being the first to launch high-quality projects and modal coins, CXMDirect Global provides professional and secure digital asset trading and management services trusted by over 7 million users worldwide.",
        tit3: "Reviews & Ratings",
        p3: "Out of 5",
        tit4: "Message",
        label1: "Provider",
        label2: "Size",
        label3: "Compatibility",
        label4: "Language",
        value1: "Chinese and English",
        label5: "Age",
        value2: "Over 18 years old only",
        label6: "Price",
        value3: "Free",
        tit5: "Privacy policy",
        p5: "Disclaimer: This website is only for developers to provide App download and installation hosting, App content and operation related matters by the App developer is responsible for, has nothing to do with this website."
    }
};
