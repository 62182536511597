export default {
    home: {
        language: "भाषा स्विचिंग",
        banner_p1: "विदेशी परिवर्तन, वस्तुओं, इंडिक्स, प्लेटफ़ॉर्म के माध्यम संग्रह",
        banner_p2: "",
        banner_msg1: "और अधिक लोकप्रिय आर्थिक उत्पादन",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "अब प्रयोग के लिए रेजिस्टर करें और प्रारंभ करें",
        banner_link2: "अब रेजिस्टर करें",
        swiper_h1: "उच्च प्रकार्य",
        swiper_h2: "मिलान इंजिनName",
        swiper_h3: "आर्थिक स्तर",
        swiper_h4: "सुरक्षा सुरक्षा",
        swiper_h5: "उत्पादन",
        swiper_h6: "धन और विविध",
        swiper_h7: "विश्वव्यापीकरण",
        swiper_h8: "विन्यास",
    },
    list: {
        title: "पोपलियर",
        th1: "स्पोट",
        th2: "अन्तिम मूल्य",
        th3: "24 घण्टा",
        th4: "मार्केट",
        th5: "व्यापार के लिए",
    },
    info1: {
        tit1: "प्लेटफॉर्म कोर्डर उत्पादन",
        tit2: "सदैव कंट्रांट स्थान",
        p1: "तीसरी पीढ़ी फ्यूज़न इंजिन",
        p2: "एक शक्तिशाली मैचिंग सिस्टम सुरक्षित है कि प्रयोक्ताओं के सभी मुख्य आपरेशन्स १०० मिलिसेकंड",
        p3: "निर्देशित रूप से विकृत गुण ट्रांसेक्शन फंक्शन",
        p4: "विशेषता व्यापारिक फंक्शन जैसे ऑफलाइन क्रमबद्ध, सुपर कंसोलिडेशन, विविधित रोक गया और हराम को रोकें, दोहरी क्रमबद्ध क्रमबद्ध स्",
        p5: "फ्लैक्सिबल ट्रेडिंग योजनाधिकार",
        p6: "सभी स्तरों में ट्रेडर्स के जीवित प्राथमिकताओं को पूरा करने के लिए बहुत स्तर विकल्प प्रदान करें",
        btn: "ट्रेडिंग प्रारंभ करें"
    },
    info2: {
        tit1: "ट्रांसेक्शन फंक्शन 1",
        tit2: "के- लाइनक्रम रखें",
        p1: "एक क्लिक के साथ तुरंत रूप से आदेश रखें",
        p2: "के- लाइन ट्रेडिंग संपूर्ण नेटवर्क पर कोइन्वी द्वारा प्रारंभिक अनुभव के पूरे सेट है. शक्तिशाली के- लाइन ट्रेडिंग फंक्शन पर आधारित, व्यापार के पृष्ठ पर सीधे के लिए के",
        p3: "संपूर्ण ट्रांसेक्शन प्रबंधन",
        p4: "Users can easily and efficiently complete various transaction operations on the K line, including warehouse operations and order operations. प्रयोक्ताओं को जल्दी आदेश रखने के लिए सक्षम करें और जब बाजार तेजी से बदलें",
    },
    info3: {
        tit1: "ट्रांसेक्शन फंक्शन 2",
        tit2: "सुपर भण्डार",
        p1: "बहुत स्थितियों के मुक्त स्विचिंग",
        p2: "उपयोक्ताओं एक ही मुद्रा में दोनों लांबे और छोटे दिशाओं में एक संयुक्त स्थिति रख सकते हैं, और एन अलग लेवर्स के साथ बहुत साब स्थितियाँ हैं, और मुक्त संयुक्त करन",
        p3: "स्वतन्त्र स्थिति जोखिल घटाएँ",
        p4: "मुद्रा को बहुत स्थितियों को भिन्न स्तरों के साथ रख सकता है, और हर उपस्थिति स्वयंपूर्ण रूप स्थितियों को स्वयंपूर्ण रूप से अनुकूलित कर सकता है, वृद्धि या कम कर सकता है, और लाभ सेट करें और ख",
    },
    info4: {
        tit1: "ट्रांसेक्शन फंक्शन तीन",
        tit2: "स्वतन्त्र स्थितियाँ जोखियाई कम करते हैं",
        p1: "तीन महत्वपूर्ण लाभ और गुमावट कार्य",
        p2: "CXMDirect तीन मोड प्रदान करता है: पूरी स्थिति स्थिति नुकसान, आंशिक स्थिति स्थिति नुकसान, और प्रयोक्ताओं को व्याप",
        p3: "50 विभिन्न रोका गया और हराम स्थितियों को रोकें",
        p4: "उपयोक्ताओं लाभ बन्द कर सकते हैं और अपने स्थिति और आदेशों में किसी बारे में हराम बिन्दुओं को रोक सकते हैं, धीर्मिक नियं उसी समय, यह 50 नाश की आदेशों को सेट करने के लिए समर्थन करता है, जिसमें सीमित आदेश या बाजार क्रम, एक ही स्थिति में भिन्न शीट और क्रमों के लिए, प्रयोक्त",
    },
    info5: {
        p1: "बहुत-तत्व",
        p2: "संस्थापित मीडिया चैनल समर्थन",
        p3: "शीर्ष केएल सहकारी",
        p4: "लाखो उपयोक्ताओं का चयन",
    },
    why: {
        p1: "IDEX क्यों चुनें",
        p2: "शक्तिशाली स्वतंत्र संशोधन और विकास क्षमताएँ",
        p3: "0 से 1 से तीसरी पीढ़ी व्यापार सिस्टम को निर्देशित रूप से विकास करें",
        p4: "अत्यंत कार्यान्वयन के साथ अंतिम ट्रेडिंग अनुभव बनाएँ",
        p5: "ऊपर स्तर सुरक्षा",
        p6: "हॉट और थंड वालेट के अकेलेशन के लिए रिस्क नियंत्रण",
        p7: "अपने मालिकों की सुरक्षा सुरक्षित करने के लिए द्वितीय माप",
        p8: "एक सम्पूर्ण रोसिस नियंत्रण सिस्टम",
        p9: "दोहरी मूल्य बलवान समानीकरण सुरक्षा मक्षम",
        p10: "प्रयोक्ता प्रदर्शन रोसिस कम करें",
        p11: "वैश्विक लेआउट",
        p12: "वैश्विक एलाइट संग्रह",
        p13: "वैश्विक टीम वितरण",
        p14: "7 * 24 घण्टा सेवा",
        p15: "1 मिनिट प्रतिक्रिया यंत्राधिकार",
        p16: "वैश्विक प्रयोक्ता के लिए 24/7 सेवा",
    },
    info6: {
        p1: "डिजिटल मुद्रा ट्रेडिंग शुरू करें?",
        p2: "अब अपने क्रिप्टोवेरेंसी निवेश प्रारंभ करें",
    },
    foot: {
        download: "डाउनलोड",
        copy_success: "सफलतापूर्वक नक़ल किया गया",
        copy_error: "नकल असफल",
    },
    download: {
        h1: "请使用手机扫描二维码下载",
        copy: "复制",
        down: "下载",
        rate: "评分",
        age: "年龄",
        complain: "应用投诉",
        tit1: "新功能",
        version: "版本",
        p1: "此版本包括错误修复和性能改进",
        tit2: "应用介绍",
        p2: "欢迎来到 IDEX Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。作为世界领先的加密货币交易所，CXMDirect以率先推出优质项目和模因币而闻名, CXMDirect Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。",
        tit3: "评论及评分",
        p3: "满分5分",
        tit4: "信息",
        label1: "供应商",
        label2: "大小",
        label3: "兼容性",
        label4: "语言",
        value1: "中文和英文",
        label5: "年龄",
        value2: "限18岁以上",
        label6: "价格",
        value3: "免费",
        tit5: "隐私政策",
        p5: "免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。"
    }
};
